import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/line-coupon.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="LINE友達紹介キャンペーン"
		  desc="このサンプルはLINEで友達紹介キャンペーンを行う方法です。既に公式アカウントと友達の顧客から友達を紹介してもらうことで、リード獲得になりやすい顧客を獲得できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/line/instant-coupon'}
		  type={'Article'}
		  shortName="LINE友達紹介キャンペーン"
		  createdAt="2021-11-30"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="LINE友達紹介キャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="LINE友達紹介キャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/line',
		  name: 'LINEキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"LINE友達紹介キャンペーン"}
		description={<>このサンプルはLINEで友達紹介キャンペーンを行う方法です。<br/>
		  既に公式アカウントと友達の顧客から友達を紹介してもらうことで、趣味・属性がリード獲得を獲得できます。<br/>
		  紹介される側が大きなメリットを受けやすいように設計することが重要です。
		</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS
		  ,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS
		  , Constants.CAMPAIGN_EFFECTS.GET_FRIENDS
		  , Constants.CAMPAIGN_EFFECTS.GET_MIDDLE_SPREAD
		  , Constants.CAMPAIGN_EFFECTS.CONNECT_LINE_BUSINESS
		  , Constants.CAMPAIGN_EFFECTS.GET_CUSTOMER_LEAD
		  , Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT
		  , Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE
		]}
		plan={'ベーシックプラン以上'}
		promotionId="afddb161e84921d630"
		platformExample={
		  <PlatformExample
			title="その他のLINEキャンペーン"
			types={['lineReceipt','twitterToLine','lineInstantWin','lineCoupon']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples